.app {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.top {
  flex: 0;
  border-bottom: solid 1px #eeeeee;
}

.body {
  flex: 1;
  display: flex;
  flex-direction: column;
  /*justify-content: center;*/
  /*align-items: center;*/
  margin: 8px;
}

.bottom {
  flex: 0;
  border-top: solid 1px #eeeeee;
}
